import React from 'react'
import biserica from '../../assets/betel1.jpeg'
import {conducator01} from './imports'
import './conducere.css'

const Conducere = () => {
    const handleButtonClick = (event) => {
        event.preventDefault(); // Prevents the default anchor click behavior
      }
    return (
        <div className='betel__conducere section__padding' id="conducere">
            <div className='container'>
                <h1 className='heading'>Conducere</h1>
                <div className='card-wrapper'>
                    <div className='card'>
                        <img src={biserica} alt='card background' className='card-img'/>
                        <img src={conducator01} alt='profile' className='profile-img'/>
                        <h1>Nichitean Dorel</h1>
                        <p className='job-title'>Prezbiter</p>
                        <button className='btn pastor1' onClick={handleButtonClick}></button>
                        <ul className='social-media'>
                            {/*<li><a href='#'><img src={mail} alt='mail icon'/></a></li>
                            <li><a href='#'><img src={facebook} alt='facebook icon'/></a></li>
                            <li><a href='#'><img src={instagram} alt='instagram icon'/></a></li>*/}
                        </ul>
                    </div>
                    <div className='card'>
                        <img src={biserica} alt='card background' className='card-img'/>
                        <img src={conducator01} alt='profile' className='profile-img'/>
                        <h1>Butnaru Cristian</h1>
                        <p className='job-title'>Diacon</p>
                        <button className='btn pastor2' onClick={handleButtonClick}></button>
                        <ul className='social-media'>
                        </ul>
                    </div>
                    <div className='card'>
                        <img src={biserica} alt='card background' className='card-img'/>
                        <img src={conducator01} alt='profile' className='profile-img'/>
                        <h1>Lucescu Valentin</h1>
                        <p className='job-title'>Casier</p>
                        <button className='btn pastor3' onClick={handleButtonClick}></button>
                        <ul className='social-media'>
                        </ul>
                    </div>
                    <div className='card'>
                        <img src={biserica} alt='card background' className='card-img'/>
                        <img src={conducator01} alt='profile' className='profile-img'/>
                        <h1>Andronic Daniel</h1>
                        <p className='job-title'>Membru cenzor</p>
                        <button className='btn pastor4' onClick={handleButtonClick}></button>
                        <ul className='social-media'>
                        </ul>
                    </div>
                    <div className='card'>
                        <img src={biserica} alt='card background' className='card-img'/>
                        <img src={conducator01} alt='profile' className='profile-img'/>
                        <h1>Lutichevici Stelian</h1>
                        <p className='job-title'>Membru cenzor</p>
                        <button className='btn pastor5' onClick={handleButtonClick}></button>
                        <ul className='social-media'>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Conducere