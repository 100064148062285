import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Contact, Despre_noi, Footer, Header, Servicii_inchinare, Conducere, Mapa, SarbatoareaMultumirii, Conferinta, Botez } from './containers';
import {CustomNavbar } from './components';
import './App.css'
import Conferinta_2024 from './containers/conferinta_2024/conferinta_2024';

const App = () => {
  document.title = "Biserica Penticostală Română Betel"; // Replace "New Title Name" with your desired title

  return (
    <>
      <CustomNavbar/>
      <Header />
      <Despre_noi/>
      <Servicii_inchinare/>
      <Conducere/>
      <Conferinta />
      <SarbatoareaMultumirii />
      <Botez />
      <Conferinta_2024 />
      <Contact/>
      <Mapa/>
      <Footer/>
    </>
  );
}

export default App